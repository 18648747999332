
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.backendcalendarwithashraful.cloudns.ch/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (data.token) {
        console.log('Login successful:', data);
        localStorage.setItem('token', data.token);
        navigate('/dashboard');
      } else {
        alert('Login failed!');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login error!');
    }
  };

  return (
    <div>
      <div className="custom-bg-color py-3 mb-4 pl-5" style={{ marginLeft: '-15px', marginRight: '-15px', display: 'flex', alignItems: 'center' }}>
        <h2 className="text-left text-white" style={{ fontSize: '38px', fontWeight: '500', color: '', fontFamily: '' }}>Calendar</h2>
        <div style={{ width: '6px' }}></div> {/* Add space */}
        <h2 className="text-left text-white" style={{ fontSize: '14px', fontWeight: '450', color: '', fontFamily: '' }}>with ashraful</h2>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username" style={{ marginBottom: '16px',fontSize: '20px', fontWeight: '800', color: 'Green', fontFamily: '' }}>Username</label>
                    <input type="text" className="form-control" id="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" style={{ marginBottom: '16px',fontSize: '20px', fontWeight: '800', color: 'Green', fontFamily: '' }}>Password</label>
                    <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <button type="submit" style={{
                            backgroundColor: 'green', // Blue background color
                            color: '#ffffff', // White text color
                            fontWeight: 'bold', // Bold font weight
                            borderRadius: '5px', // Rounded corners
                            border: 'none', // No border
                            padding: '6px 10px', // Padding
                            cursor: 'pointer', // Cursor style
                            width: '80px', // Custom width
                            height: '38px'}}>Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
