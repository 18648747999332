import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Use useNavigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you would make your API call to the registration endpoint
    try {
      const response = await fetch('https://www.backendcalendarwithashraful.cloudns.ch/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      const data = await response.json();
      if (data.success) { // Assuming your API responds with a success flag
        console.log('Registration successful:', data);
        // Optionally save any data like tokens, then redirect
        navigate('/login'); // Redirect user to login page after successful registration
      } else {
        // Handle errors or unsuccessful registration
        alert('Registration failed!');
      }
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration error!');
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
